<template>
	<div>
		<el-drawer
			title="我是标题"
			:visible.sync="drawer"
			direction="rtl"
			:before-close="resetForm"
			:with-header="false"
		>
			<div class="titlebox">
				<span>新增项目</span>
				<i @click="resetForm" class="el-icon-close" style="color: #000000;font-size: 20px;"></i>
			</div>
			<div class="formbox">
				<el-form
					:model="ruleForm"
					:rules="rules"
					ref="ruleForm"
					label-width="0"
					class="demo-ruleForm"
				>
					<el-form-item label="" prop="customerId">
						<div class="inputtitle">
							<span>*</span>
							项目客户：
						</div>
						<div class="inputbox">
							<el-select
								@change="chooseCustomer"
								v-model="ruleForm.customerId"
								placeholder="请选择"
							>
								<el-option
									v-for="item in customerLists"
									:key="item.customerId"
									:label="item.customerName"
									:value="item.customerId"
								></el-option>
							</el-select>
						</div>
					</el-form-item>
					<el-form-item label="" prop="projectName" style="margin-top: 30px;">
						<div class="inputtitle">
							<span>*</span>
							项目名称：
						</div>
						<div class="inputbox">
							<el-input v-model="ruleForm.projectName" placeholder="请输入公司名称"></el-input>
						</div>
					</el-form-item>
					<el-form-item label="" prop="contractParams" style="margin-top: 30px;">
						<div class="inputtitle">
							<span>*</span>
							联系人
						</div>
						<div class="onerelation" v-for="(item, index) in ruleForm.contractParams" :key="index">
							<span>联系人{{ index + 1 }}：</span>
							<p>{{ item.userName }}</p>
							<p>{{ item.positionName }}</p>
							<p>{{ item.contractPhone }}</p>
							<div class="flex" style="cursor: pointer;">
								<i
									class="el-icon-delete"
									style="color: #CCCCCC;margin-right: 10px;"
									@click="clickDeletePersonal(index)"
								></i>
								<div @click="clickAddPersonal" v-if="ruleForm.contractParams.length == index + 1">
									<i class="el-icon-circle-plus-outline" style="color: #2E77F6;"></i>
									<span style="color: #2E77F6;margin: 0 0 0 10px;">添加(点击添加联系人)</span>
								</div>
							</div>
						</div>
						<div
							style="margin: 0 0 0 20px;cursor: pointer;"
							@click="clickAddPersonal"
							v-if="ruleForm.contractParams.length < 1"
						>
							<i class="el-icon-circle-plus-outline" style="color: #2E77F6;"></i>
							<span style="color: #2E77F6;margin: 0 0 0 10px;">添加(点击添加联系人)</span>
						</div>
						<div class="addinputbox">
							<span>联 系 人 ：</span>
							<input v-model="addpersoninfo.userName" placeholder="请输入联系人姓名" />
						</div>
						<div class="addinputbox">
							<span>担任职位：</span>
							<input v-model="addpersoninfo.positionName" placeholder="请输入联系人职务" />
						</div>
						<div class="addinputbox">
							<span>联系方式：</span>
							<input v-model="addpersoninfo.contractPhone" placeholder="请输入联系人电话" />
						</div>
					</el-form-item>
					<el-form-item label="" prop="leaderId" style="margin-top: 30px;">
						<div class="inputtitle">
							<span>*</span>
							项目负责人：
						</div>
						<div class="inputbox">
							<el-select v-model="ruleForm.leaderId" @change="chooseLeader">
								<el-option
									v-for="item in employeesList"
									:key="item.userId"
									:value="item.userId"
									:label="item.realName"
								></el-option>
							</el-select>
						</div>
					</el-form-item>
					<el-form-item label="" prop="personIds" style="margin-top: 30px;">
						<div class="inputtitle">
							<span>*</span>
							项目人员：
						</div>
						<div class="inputbox">
							<el-select v-model="ruleForm.personIds" multiple>
								<el-option
									v-for="item in employeesList"
									:key="item.userId"
									:value="item.userId"
									:label="item.realName"
								></el-option>
							</el-select>
						</div>
					</el-form-item>
					<el-form-item label="" prop="startTime" style="margin-top: 30px;">
						<div class="inputtitle">
							<span>*</span>
							项目开始时间：
						</div>
						<div class="inputbox">
							<el-date-picker
								value-format="yyyy-MM-dd HH:mm"
								v-model="ruleForm.startTime"
								type="date"
								placeholder="选择日期"
							></el-date-picker>
						</div>
					</el-form-item>
					<el-form-item label="" prop="endTime" style="margin-top: 30px;">
						<div class="inputtitle">
							<span>*</span>
							项目结束时间：
						</div>
						<div class="inputbox">
							<el-date-picker
								value-format="yyyy-MM-dd HH:mm:ss"
								v-model="ruleForm.endTime"
								type="date"
								placeholder="选择日期"
							></el-date-picker>
						</div>
					</el-form-item>
					<el-form-item label="" prop="requirementDesc" style="margin-top: 30px;">
						<div class="inputtitle">需求描述：</div>
						<div class="inputbox">
							<el-input v-model="ruleForm.requirementDesc" placeholder="请输入需求描述"></el-input>
						</div>
					</el-form-item>
				<!-- 	<el-form-item label="" prop="" style="margin-top: 30px;">
						<div class="inputtitle">项目阶段：</div>
						<div class="inputbox">
							<el-select>
								<el-option v-for="item in projectStatus" :key="item"
									:value="item"
									:label="item"
								></el-option>
							</el-select>
						</div>
					</el-form-item> -->

					<el-form-item label="" prop="domain" style="margin-top: 30px;">
						<div class="inputtitle">服务器域名：</div>
						<div class="inputbox">
							<el-input v-model="ruleForm.domain" placeholder="请输入公司名称"></el-input>
						</div>
					</el-form-item>
					<el-form-item label="" prop="serverExpire" style="margin-top: 30px;">
						<div class="inputtitle">服务器到期时间：</div>
						<div class="inputbox">
							<el-date-picker
								value-format="yyyy-MM-dd HH:mm"
								v-model="ruleForm.serverExpire"
								type="date"
								placeholder="选择日期"
							></el-date-picker>
						</div>
					</el-form-item>
					<el-form-item label="" prop="saleServiceYear" style="margin-top: 30px;">
						<div class="inputtitle">售后年限：</div>
						<div class="inputbox1">
							<el-input-number
								v-model="ruleForm.saleServiceYear"
								:precision="2"
								:step="0.5"
								:min="0"
								:max="10"
							></el-input-number>
							<span class="yeartext">年</span>
							<!-- <el-input v-model="ruleForm.saleServiceYear" placeholder="请输入公司名称"></el-input> -->
						</div>
					</el-form-item>
					<el-form-item label="" prop="saleServicePrice" style="margin-top: 30px;">
						<div class="inputtitle">售后服务价格：</div>
						<div class="inputboxprice">
							<el-input v-model="ruleForm.saleServicePrice" placeholder="请输入售后服务价格"></el-input>
							<span class="yeartext">元/年</span>
						</div>
					</el-form-item>
					<el-form-item label="" prop="projectAccount" style="margin-top: 30px;">
						<div class="inputtitle">测试账号</div>
						<div class="onerelation" v-for="(item, index) in ruleForm.projectAccount" :key="index">
							<span>测试账号{{ index + 1 }}：</span>
							<p>{{ item.account }}</p>
							<p>{{ item.accountName }}</p>
							<p>{{ item.loginPassword }}</p>
							<div class="flex" style="cursor: pointer;">
								<i
									class="el-icon-delete"
									style="color: #CCCCCC;margin-right: 10px;"
									@click="clickDeleteAccount(index)"
								></i>
								<div @click="clickAddAccount" v-if="ruleForm.projectAccount.length == index + 1">
									<i class="el-icon-circle-plus-outline" style="color: #2E77F6;"></i>
									<span style="color: #2E77F6;margin: 0 0 0 10px;">添加(点击添加测试账号)</span>
								</div>
							</div>
						</div>
						<div
							style="margin: 0 0 0 20px;cursor: pointer;"
							@click="clickAddAccount"
							v-if="ruleForm.projectAccount.length < 1"
						>
							<i class="el-icon-circle-plus-outline" style="color: #2E77F6;"></i>
							<span style="color: #2E77F6;margin: 0 0 0 10px;">添加(点击添加测试账号)</span>
						</div>
						<div class="addinputbox">
							<span>账号名称 ：</span>
							<input v-model="accountInfo.accountName" placeholder="请输入账号名称" />
						</div>
						<div class="addinputbox">
							<span>账号：</span>
							<input v-model="accountInfo.account" placeholder="请输入账号" />
						</div>
						<div class="addinputbox">
							<span>密码：</span>
							<input v-model="accountInfo.loginPassword" placeholder="请输入密码" />
						</div>
					</el-form-item>
				</el-form>
				<div class="twobtn">
					<div @click="resetForm()">取消</div>
					<div class="save" @click="submitForm()">保存</div>
				</div>
			</div>
		</el-drawer>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import {
	addCustomer,
	customerList,
	allUserList,
	stageListAll,
	addProject,
	customerInfo
} from '@/network/api.js';

export default {
	data() {
		return {
			accountInfo: {
				// 添加账号信息
				account: '', //  账号
				accountId: '',
				accountName: '', //  账号名称
				loginPassword: '' // 登录密码
			},
			customerLists: [], // 客户列表
			employeesList: [], // 员工列表
			addpersoninfo: {
				contractId: '', //联系人id
				contractPhone: '', //联系人手机号
				positionName: '', //联系人职务
				userName: '' //联系人姓名
			},
			ruleForm: {
				contractParams: [], // 联系人
				customerId: '', // 客户id
				projectName: '', // 项目名称
				leaderId: '', //项目负责人id
				leaderName: '', // 项目负责人姓名
				personIds: [], // 项目人员
				startTime: '', // 项目开始时间
				endTime: '', // 项目结束时间
				domain: '', // 服务器域名
				projectStageId: '', //  项目阶段
				serverExpire: '', // 服务器到期时间
				saleServiceYear: 0, // 售后年限
				projectAccount: [] ,// 测试账号
				saleServicePrice: '',   // 售后服务价格
			},
			rules: {
				customerId: [{ required: true, message: '请选择客户', trigger: 'blur' }],
				projectName: [{ required: true, message: '请输入项目名称', trigger: 'blur' }],
				leaderId: [{ required: true, message: '请选择项目负责人', trigger: 'blur' }],
				contractParams: [
					{ type: 'array', required: true, message: '请填写联系人', trigger: 'blur' }
				],
				personIds: [
					{ type: 'array', required: true, message: '请选择项目联系人', trigger: 'blur' }
				],
				startTime: [{ required: true, message: '请选择项目开始时间', trigger: 'blur' }],
				endTime: [{ required: true, message: '请选择项目结束时间', trigger: 'blur' }]
				// customerName: [{ required: true, message: '请输入公司名称', trigger: 'blur' }]
			},
			drawer: false
		};
	},
	computed: {
		...mapState(['projectStatus']),
		...mapState('systemModule', {
			companyTypeList: 'companyTypeList',
			cluesSourceList: 'cluesSourceList',
			companyIndustryList: 'companyIndustryList',
			customerType: 'customerType'
		})
	},
	created() {
		this._customerList();
		this._allUserList();
		// this._stageListAll();
	},
	methods: {
		// 选择负责人，获取负责人名称
		chooseLeader(val) {
			this.employeesList.forEach(item => {
				if (item.userId == val) {
					this.ruleForm.leaderName = item.realName
				}
			})
		},
		// 切换客户
		chooseCustomer(val) {
			// console.log(val);
			this._customerInfo(val);
		},
		// 获取客户详情(获取所选客户的联系人)
		async _customerInfo(id) {
			const res = await customerInfo({ customerId: id });
			this.ruleForm.contractParams = res.data.contactVo.map(item => {
				return {
					contractId: item.contactId,
					contractPhone: item.contactPhone,
					positionName: item.positionName,
					userName: item.userName
				};
			});
		},
		// 添加项目
		async _addProject() {
			const loading = this.$loading({
				// 登录是的loading
				lock: true,
				text: 'Loading',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});
			let settime = setTimeout(function() {
				loading.close();
			}, 3000);
			const res = await addProject(this.ruleForm);
			clearTimeout(settime);
			loading.close();
			this.$myMessage('添加成功', 'success');
			this.resetForm();
			this.$emit('updataInfo');
		},
		// 点击添加测试账号
		clickAddAccount() {
			let { account } = this.accountInfo;
			if (!account) {
				return this.$myMessage('请填写账号', 'error');
			}
			let info = JSON.parse(JSON.stringify(this.accountInfo));
			this.ruleForm.projectAccount.push(info);
			this.accountInfo = {
				// 添加账号信息
				account: '', //  账号
				accountId: '',
				accountName: '', //  账号名称
				loginPassword: '' // 登录密码
			};
		},
		// 删除一个测试账号
		clickDeleteAccount(index) {
			this.ruleForm.projectAccount.splice(index, 1);
		},

		// 获取所有员工列表
		async _allUserList() {
			const res = await allUserList();
			// console.log('员工列表', res);
			this.employeesList = res.data;
		},
		// 获取客户列表
		async _customerList() {
			const res = await customerList({
				isAsc: 'asc',
				page: 1,
				inSea: '0',
				isWait: '0', // 是否是待跟进
				pageSize: 100000,
				status: 2, //0 线索(跟进中的客户) 1 商机(添加商机的客户) 2 客户(已合作的客户)
				type: '0' // 0 我的 1 团队
			});
			// console.log('客户列表', res);
			this.customerLists = res.data.list;
		},

		// 点击删除联系人
		clickDeletePersonal(index) {
			this.ruleForm.contractParams.splice(index, 1);
		},
		// 点击添加联系人
		clickAddPersonal() {
			let { contractPhone, positionName, userName } = this.addpersoninfo;
			if (!userName) {
				return this.$myMessage('请填写联系人姓名', 'error');
			}
			let info = JSON.parse(JSON.stringify(this.addpersoninfo));
			this.ruleForm.contractParams.push(info);
			this.addpersoninfo = {
				contractId: '', //联系人id
				contractPhone: '', //联系人手机号
				positionName: '', //联系人职务
				userName: '' //联系人姓名
			};
		},

		submitForm() {
			console.log(this.ruleForm);
			this.$refs['ruleForm'].validate(valid => {
				if (valid) {
					// console.log(this.ruleFrom);
					// return;
					this._addProject();
				} else {
					this.$myMessage('请将信息填写完整', 'error');
					return false;
				}
			});
		},
		resetForm() {
			this.$refs['ruleForm'].resetFields();
			this.drawer = false;
			// this.ruleForm.chanceParams = {
			// 	// 商机信息
			// 	chanceDesc: '', // 商机描述
			// 	chanceTitle: '', // 商机标题
			// 	docList: []
			// };
			// this.addpersoninfo = {
			// 	contactId: '', //联系人id
			// 	contactPhone: '', //联系人手机号
			// 	positionName: '', //联系人职务
			// 	userName: '' //联系人姓名
			// };
		}
	}
};
</script>

<style scoped lang="scss">
.yeartext {
	margin: 0 0 0 20px;
	width: 50px;
}
::v-deep {
	.el-form-item__content {
		line-height: 20px;
	}
}
.deleteicon {
	cursor: pointer;
	width: 16px;
	height: 16px;
}
.box2 {
	display: flex;
	align-items: center;
	img {
		width: 18px;
		height: 17px;
		margin: 0 12px 0 0;
	}
	span {
		font-size: 14px;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #3286ff;
	}
}
.box1:hover {
	background-color: #faf9fb;
}
.box1 {
	margin: 0 0 15px 17px;
	width: 598px;
	height: 34px;
	justify-content: space-between;
	display: flex;
	align-items: center;
	padding: 0 20px 0 12px;
}
.upbox:active {
	opacity: 0.6;
}
.upbox {
	cursor: pointer;
	margin: 0 0 0 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 108px;
	height: 32px;
	background: rgba(46, 119, 246, 0.08);
	border-radius: 4px;
	border: 1px solid #2e77f6;
	i {
		color: #3286ff;
	}
	span {
		font-size: 0.875em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #3286ff;
		margin: 0 0 0 1em;
	}
}
::v-deep {
	.inputboxprice {
		display: flex;
		align-items: center;
		.el-input__inner {
			width: 520px !important;
			height: 40px !important;
		}
		.el-input {
			width: 520px !important;
			height: 40px !important;
		}
	}
	.inputbox1 {
		.el-input__inner {
			width: 180px !important;
			height: 40px !important;
		}
		// span {
		// 	margin: 0 0 0 20px;
		// }
	}
	.inputbox {
		.el-input__inner {
			width: 600px !important;
			height: 40px !important;
		}
	}

	.el-form-item {
		margin: 0;
	}
}
.addinputbox {
	display: flex;
	align-items: center;
	margin: 15px 0 0 0;
	padding: 0 0 0 17px;
	span {
		font-size: 14px;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #444444;
	}
	input {
		width: 400px !important;
		height: 40px;
		border-radius: 4px;
		border: 1px solid #eeeeee;
	}
}
.onerelation {
	padding: 0 0 0 17px;
	display: flex;
	align-items: center;
	margin: 0 0 20px 0;
	span {
		font-size: 14px;
		color: #aeaeae;
		margin: 0 10px 0 0;
	}
	i {
		cursor: pointer;
	}
	p {
		margin: 0 20px 0 0;
		font-size: 14px;
		color: #333333;
	}
}
.formbox {
	width: 100%;
	padding: 30px 40px;
	input:focus {
		border: 1px solid #4d91ff;
	}
	input {
		padding: 0 20px;
		width: 100%;
		outline: none;
		border: 1px solid #eeeeee;
		height: 40px;
		font-size: 14px;
		border-radius: 4px;
	}
}
.inputtitle {
	margin: 0 0 15px 0;
	display: flex;
	align-items: center;
	font-size: 14px;
	font-family: PingFang-SC-Medium, PingFang-SC;
	font-weight: 500;
	color: #666666;
	span {
		color: #ff0000;
		margin: 0 10px 0 0;
	}
}
.oneway {
	cursor: pointer;
	margin: 0 30px 10px 0;
	display: flex;
	align-items: center;
	div {
		width: 14px;
		height: 14px;
		background: #ffffff;
		border: 1px solid #cccccc;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 10px 0 0;
		&.activeway {
			border: 1px solid #2e77f6;
		}
		p {
			width: 8px;
			background-color: #2e78f6;
			height: 8px;
			border-radius: 50%;
		}
	}
}
.allway {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	padding: 0 0 0 17px;
}
::v-deep {
	.el-input__inner {
		width: 520px !important;
		height: 40px !important;
	}
}
.twobtn {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 70px 0 0 0;
	div:active {
		opacity: 0.6;
	}
	div {
		cursor: pointer;
		width: 180px;
		height: 40px;
		background: #f7f7f7;
		border-radius: 4px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
		&.save {
			background: linear-gradient(270deg, #2d74f4 0%, #3286ff 100%);
			color: #ffffff;
			margin: 0 0 0 50px;
		}
	}
}
textarea:focus {
	border: 1px solid #4d91ff;
}
textarea {
	font-size: 14px;
	outline: none;
	padding: 13px 20px;
	width: 100%;
	height: 90px;
	background: #ffffff;
	border-radius: 4px;
	border: 1px solid #eeeeee;
	max-width: 100%;
	min-width: 100%;
	min-height: 50px;
}
.titlebox {
	border-bottom: 1px solid #eeeeee;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 52px;
	padding: 0 20px;
	i {
		cursor: pointer;
	}
	span {
		font-size: 1.125rem;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #303030;
	}
}
::v-deep {
	.el-drawer {
		width: 700px !important;
		border-radius: 10px 0px 0px 10px !important;
	}
}
</style>
