<template>
	<div class="gonghai">


	
		<div class="tablebox">

			<div class="addbox" @click="showAddClues" v-if="permissionList.includes('myCluesManage:add') || isAdmin">
				<i class="el-icon-plus" style="color: #ffffff;"></i>
				<span>新增</span>
			</div>

			<div>
				<el-table :data="tableData" border style="width: 100%;margin-top: 1.25em;" @row-click="clickRow">
					<el-table-column type="index" label="序号" width="80"></el-table-column>
					<el-table-column prop="customerName" label="任务详情"></el-table-column>
					<el-table-column prop="companyTypeName" label="所属项目"></el-table-column>
					<el-table-column prop="companyTypeName" label="创建者"></el-table-column>
					<el-table-column prop="companyTypeName" label="优先级"></el-table-column>
					<el-table-column prop="companyTypeName" label="状态">
						<template slot-scope="scope">
							<div class="statusbox">
								<div>待解决</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="companyTypeName" label="指派给谁"></el-table-column>
					<el-table-column prop="companyTypeName" label="创建日期"></el-table-column>
					<el-table-column prop="companyTypeName" label="截止日期"></el-table-column>
			<!-- 		<el-table-column prop="address" label="位置信息">
						<template slot-scope="scope">
							<div class="textbox">
								{{scope.row.address}}
							</div>
						</template>
						
					</el-table-column> -->
	<!-- 				<el-table-column prop="customerDesc" label="公司简介">
						<template slot-scope="scope">
							<div class="textbox">
								{{scope.row.customerDesc}}
							</div>
						</template>
					</el-table-column> -->
			
					<el-table-column label="操作" width="250" v-if="searchInfo.type === '0'">
						<template slot-scope="scope">
							<div class="twobtn">
								<div class="kanbox" @click.stop="goDetail(scope.row)">
									<img src="../../assets/btnicon/image7.png" >
									看
								</div>
								<div class="kanbox zeng" @click.stop="showAdd(scope.row)" v-if="permissionList.includes('myCluesManage:chance') || isAdmin">
									<img src="../../assets/btnicon/image3.png" >
									改
								</div>
								<div class="kanbox gai" @click.stop="_customerInfo(scope.row.customerId)" v-if="permissionList.includes('myCluesManage:edit') || isAdmin">
									<img src="../../assets/btnicon/image3.png" >
									ok
								</div>
							</div>
						</template>
					</el-table-column>
				</el-table>
				
				<div class="pagebox">
					<el-pagination
						background
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
						:current-page="searchInfo.page"
						:page-sizes="[5, 10, 15, 20]"
						:page-size="searchInfo.pageSize"
						layout="total, sizes, prev, pager, next, jumper"
						:total="totalCount"
					></el-pagination>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	import  {customerList,customerInfo,taskList} from "@/network/api.js"
	import { mapState } from "vuex"

export default {
	data() {
		return {
			cluesIndex: 1,
			totalCount: 0,
			myCluesCount: 0,
			teamCluesCount: 0,
			
			searchInfo: {
				assignUserId: '',  //项目接受人id
				projectId: '',
				taskId: '',
				isAsc: 'asc',
				orderByColumn: '',
				page: 1,
				pageSize: 10,
				searchBody: '',

			},
			tableData: []
		};
	},
	computed: {
		// ...mapState(['companyTypeList'])
		...mapState('systemModule',{
			companyTypeList: 'companyTypeList',
			cluesSourceList: 'cluesSourceList'
		}),
		permissionList() {
			return this.$store.state.permissionList 
		},
		isAdmin() {
			return (this.$store.state.isadmin && localStorage.getItem('isStaff') == '0')
		},
	},
	components: {

	},
	name: 'cluesManager',
	created() {
		this._taskList()
	},
	methods: {
		// 获取任务列表数据
		async _taskList() {
			const res = await taskList(this.searchInfo)
			console.log('任务列表',res);
		},
		// 点击行数据
		clickRow(val) {
			// if (this.permissionList.includes('gonghaiManage:detail') || this.isAdmin) {
				this.goDetail(val)
			// }
		},
		// 获取我的线索数量
		async getYyCluesCount() {
			let info = JSON.parse(JSON.stringify(this.searchInfo))
			info.type = '0'
			const res = await customerList(info)
			this.myCluesCount = res.data.list.length
		},
		// 获取团队线索数量
		async getTeamCluesCount() {
			let info = JSON.parse(JSON.stringify(this.searchInfo))
			info.type = 1
			const res = await customerList(info)
			this.teamCluesCount = res.data.list.length
		},
		// 点击出现增加商机弹框
		showAdd(info) {
			this.$refs.addShangji.dialogVisible = true
			this.$refs.addShangji.addinfo.customerId = info.customerId
		},
		// 获取线索详情
		async _customerInfo(customerId) {
			const res = await customerInfo({customerId})
			console.log('线索详情',res);
			let info = res.data
			this.$refs.editClues.drawer = true
			this.$refs.editClues.ruleForm = {
				address: info.address, //公司地址
				companyIndustry: info.companySetting.industryId, //所属行业id
				companyType: info.companySetting.typeId, //公司类型id
				contactParams: info.contactVo, //联系人
				customerDesc: info.customerDesc, // 公司简介
				customerId: info.customerId, // 客户id
				customerName: info.customerName, //公司名称
				customerSource: info.companySetting.sourceId, //线索来源
				customerStatus: info.statusId, //客户状态
				customerTag: info.companySetting.tagId, // 标签id
				latitude: '', // 纬度
				longitude: '' // 经度
			}
		},
		// 点击重置
		clickReset() {
			console.log(this.companyTypeList);
			this.searchInfo.companyType = ''
			this.searchInfo.customerName = ''
			this.searchInfo.searchBody = ''
			this.searchInfo.customerSource = ''
		},
		// 获取线索列表
		async _customerList() {
			const res = await customerList(this.searchInfo)
			console.log("线索列表",res);
			// this.tableData = res.data.list
			// this.totalCount = res.data.totalCount
		},
		// 点击出现添加弹框
		showEdit() {
			this.$refs.editClues.drawer = true
		},
		// 点击添加跟进
		showAddGen(info) {
			this.$refs.addGenjin.addinfo.customerId = info.customerId
			this.$refs.addGenjin.dialogVisible = true
		},
		// 选择我的、团队线索
		chooseClues(index) {
			this.cluesIndex = index
			this.searchInfo.type = index-1+''
			this._customerList()
		},
		// 点击打开添加线索弹框
		showAddClues() {
			this.$refs.addClues.drawer = true
		},
		// 跳转线索详情
		goDetail(row) {
			// console.log(row);
			// return
			this.$router.push({
				path: "/projectdetail",
				query: {
					customerId: row.customerId,
					type: this.searchInfo.type
				}
			})
		},
		handleSizeChange(val) {
			
			this.searchInfo.pageSize = val
			this._customerList()
		},
		handleCurrentChange(val) {
			
			this.searchInfo.page = val
			this._customerList()
		}
	}
};
</script>

<style scoped lang="scss">
	.statusbox {
		display: flex;
		align-items: center;
		justify-content: center;
		div {
			width: 3.75em;
			height: 1.5em;
			background: rgba(242, 46, 24, 0.1);
			border-radius: 0.75em 0 0.75em 0.75em;
			border: 1px solid rgba(242, 46, 24, 0.6);
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 0.875em;
			font-family: PingFang-SC-Regular, PingFang-SC;
			font-weight: 400;
			color: #F22E18;
		}
		
	}
	.textbox {
		 text-overflow: -o-ellipsis-lastline;
		  overflow: hidden;
		  text-overflow: ellipsis;
		  display: -webkit-box;
		  -webkit-line-clamp: 2;
		  line-clamp: 2;
		  -webkit-box-orient: vertical;
	}
	.box3 {
		width: 2.5em;
		height: 0.1875em;
		background: #3286FF;
		border-radius: 0.125em;
		position: absolute;
		
		bottom: 0;
	}
	.box2 {
		display: flex;
		align-items: flex-end;
		p {
			font-size: 1rem;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #666666;
			&.activep {
				color: #000000;
				font-size: 1.125em;
			}
		}
		span {
			font-size: 0.75em;
			&.activespan {
				color: #000000;
			}
		}
	}
	.box1 {
		cursor: pointer;
		margin: 0 1.25em 0 0;
		height: 100%;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}
	.top1 {
		border: 1px solid #EEEEEE;
		width: 100%;
		height: 3.25em;
		background-color: #ffffff;
		display: flex;
		align-items: center;
		padding: 0 1.0625em;
		
	}
	::v-deep {
		.pagebox {
			width: 100%;
			display: flex;
			justify-content: flex-end;
			margin: 6.25em 0 0 1.25em;
			padding: 0 1.25em 0 0;
			.el-input__inner {
				color: #666666;
				width: 3em;
				height: 2.25em;
				line-height: 2.25em;
			}
			.el-select {
				.el-input__inner {
					color: #666666;
					width: 8em;
					height: 2.25em;
					line-height: 2.25em;
				}
			}
			
			.el-input__icon {
				line-height: 2.25em;
			}
		}
	}
.kanbox:active {
	opacity: 0.6;
}
.kanbox {
	cursor: pointer;
	width: 3.625em;
	height: 1.625em;
	background: #0091ff;
	border-radius: 0.8125em;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 0.675em;
	font-size: 0.875em;
	font-family: PingFang-SC-Medium, PingFang-SC;
	font-weight: 500;
	color: #ffffff;
	img {
		width: 1em;
		height: 0.9375em;
	}
	&.gen {
		background-color: #FA7E33;
		margin: 0 0 0 1.25em;
		img {
			width: 0.8125em;
			height: 1.0625em;
		}
	}
	&.gai {
		background-color: #3DC57C;
		margin: 0 0 0 1.25em;
	}
	&.zeng {
		background-color: #9F7EFF;
		margin: 0 0 0 1.25em;
	}
	img {
		width: 0.9375em;
		height: 0.9375em;
	}
}
.twobtn {
	display: flex;
	align-items: center;
	justify-content: center;
}
::v-deep {
	.cell {
		text-align: center;
	}
	tr {
		font-size: 0.975em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 400;
		color: #868e99;
	}
	th {
		background-color: #f6f7fb !important;
		font-size: 0.975em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #5b6271;
	}
}
thead {
}
.addbox:active {
	opacity: 0.6;
}
.addbox {
	// margin: 1.5625em 0 0 0;
	cursor: pointer;
	width: 6.75em;
	height: 2.25em;
	background: linear-gradient(270deg, #2d74f4 0%, #3286ff 100%);
	border-radius: 0.25em;
	display: flex;
	align-items: center;
	justify-content: center;
	span {
		font-size: 0.875rem;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #ffffff;
		margin: 0 0 0 1.25em;
	}
}
::v-deep {
	.el-button {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
.chongzhi {
	width: 5.625em;
	height: 2.25em;
	font-size: 0.875em;
}
.chaxun {
	margin: 0 1.25em 0 2.5em;
	width: 5.625em;
	height: 2.25em;
	
	// border-radius: 0.25em;
	
	font-size: 0.875em;
	font-family: PingFang-SC-Medium, PingFang-SC;
	font-weight: 500;
	
}
.tabletop {
	display: flex;
	align-items: center;
}
::v-deep {
	.el-input__inner {
		color: #666666;
		width: 10.5em;
		height: 2.25em;
		line-height: 2.25em;
	}
	.el-input__icon {
		line-height: 2.25em;
	}
}
.selectbox {
	margin: 0 0 0 2.5em;
	display: flex;
	align-items: center;
	span {
		font-size: 0.875em;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		margin: 0 0.6875em 0 0;
	}
}
.tablebox {
	
	background-color: #ffffff;
	padding: 1rem 1.25em;
}
.inputbox {
	display: flex;
	align-items: center;
	background: #ffffff;
	border-radius: 0.25em;
	border: 1px solid #e4e4e4;
	height: 2.25em;
	width: 12.5em;
	justify-content: space-between;
	input {
		outline: none;
		border: 0;
		width: 10.25em;
		font-size: 0.875em;
		padding: 0 0.375em 0 0.875em;
	}
	div {
		width: 2.25em;
		height: 2.25em;
		background: #faf9fb;
		border-radius: 0px 0.25em 0.25em 0px;
		border: 1px solid #e4e4e4;
	}
}

.gonghai {
	padding: 0.9375em 0;
}
@media screen and (max-width: 1920px) {
	.gonghai {
		font-size: 16px;
	}
}
@media screen and (max-width: 1720px) {
	.gonghai {
		font-size: 15px;
	}
}
@media screen and (max-width: 1520px) {
	.gonghai {
		font-size: 14px;
	}
}
@media screen and (max-width: 1320px) {
	.gonghai {
		font-size: 13px;
	}
}
@media screen and (max-width: 1120px) {
	.gonghai {
		font-size: 12px;
	}
}
</style>
