<template>
	<div class="addtan">
		<el-dialog :show-close="false" width="400px" :visible.sync="dialogVisible" :before-close="handleClose">
			<div class="tanbox">
				<div class="titlebox">
					<span>提示</span>
					<i @click="handleClose" class="el-icon-close" style="color: #000000;font-size: 20px;"></i>
				</div>
				<div class="formbox">
					
					<div class="text">
						{{message || '确认要'}}
					</div>
					
					
					<div class="twobtn">
						<div @click="clickCancel">取消</div>
						<div class="save" @click="clickConfirm">确认</div>
					</div>
				</div>
				
			</div>
			
			
		</el-dialog>
	</div>
</template>

<script>
export default {
	data() {
		return {
			dialogVisible: false,
			value1: '',
			wayIndex: 0,
		};
	},
	props: {
		message: {
			type: String,
		}
	},
	methods: {
		// 点击确认
		clickConfirm() {
			this.$emit('deleteInfo')
		},
		// 点击取消
		clickCancel() {
			this.dialogVisible = false
		},
		// 选择跟进方式
		chooseWay(index) {
			this.wayIndex = index
		},
		handleClose() {
			this.dialogVisible = false
		}
	}
};
</script>

<style scoped lang="scss">
	.text {
		font-size: 18px;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #444444;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 20px 0 0 0;
	}
	.oneway {
		cursor: pointer;
		margin: 0 30px 10px 0 ;
		display: flex;
		align-items: center;
		div {
			width: 14px;
			height: 14px;
			background: #FFFFFF;
			border: 1px solid #CCCCCC;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0 10px 0 0;
			&.activeway {
				border: 1px solid #2E77F6;
			}
			p {
				width: 8px;
				background-color: #2E78F6;
				height: 8px;
				border-radius: 50%;
			}
		}
	}
	.allway {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		padding: 0 0 0 17px;
	}
	::v-deep {
		.el-input__inner {
			width: 520px !important;
			height: 40px !important;
		}
	}
	.twobtn {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 70px 0 0 0;
		div:active {
			opacity: 0.6;
		}
		div {
			cursor: pointer;
			width: 180px;
			height: 40px;
			background: #F7F7F7;
			border-radius: 4px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #999999;
			&.save {
				background: linear-gradient(270deg, #2D74F4 0%, #3286FF 100%);
				color: #ffffff;
				margin: 0 0 0 50px;
			}
		}
	}
	textarea:focus {
		border: 1px solid #4D91FF;
	}
	textarea {
		font-size: 14px;
		outline: none;
		padding: 13px 20px;
		width: 100%;
		height: 90px;
		background: #FFFFFF;
		border-radius: 4px;
		border: 1px solid #EEEEEE;
		max-width: 100%;
		min-width: 100%;
		min-height: 50px;
	}
	.inputtitle {
		margin: 0 0 15px 0;
		display: flex;
		align-items: center;
		font-size: 14px;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #666666;
		span {
			color: #0091FF;
			margin: 0 10px 0 0;
		}
	}
.formbox {
	
	width: 100%;
	padding: 30px 40px;
	input:focus {
		border: 1px solid #4D91FF;
	}
	input {
		padding: 0 20px;
		width: 100%;
		outline: none;
		border: 1px solid #EEEEEE;
		height: 40px;
		font-size: 14px;
		border-radius: 4px;
	}
}
	@media screen and (max-width: 1920px) {
		.addtan {
			font-size: 16px;
		}
	}
	@media screen and (max-width: 1720px) {
		.addtan {
			font-size: 15px;
		}
	}
	@media screen and (max-width: 1520px) {
		.addtan {
			font-size: 14px;
		}
	}
	@media screen and (max-width: 1320px) {
		.addtan {
			font-size: 13px;
		}
	}
	@media screen and (max-width: 1120px) {
		.addtan {
			font-size: 12px;
		}
	}
	.titlebox {
		border-bottom: 1px solid #EEEEEE;;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 52px;
		padding: 0 20px;
		i {
			cursor: pointer;
		}
		span {
			font-size: 1.125rem;
			font-family: PingFang-SC-Medium, PingFang-SC;
			font-weight: 500;
			color: #303030;
		}
	}
	::v-deep {
		.el-dialog__header {
			padding: 0;
		}
		.el-dialog__body {
			padding: 0;
		}
		.el-dialog {
			border-radius: 10px;
		}
	}
</style>
