<template>
	<div>
		<div class="tablebox">
			<div class="tabletop">
				<div class="inputbox">
					<input placeholder="公司名称搜索" v-model="projectIinfo.searchBody"/>
					<div class="flex"><i class="el-icon-search" style="color: #AAAAAA;"></i></div>
				</div>
				<div class="selectbox">
					<span>公司类型</span>
					<el-select v-model="projectIinfo.companyIndustry" placeholder="请选择">
						<el-option
							v-for="item in companyIndustryList"
							:key="item.settingId"
							:label="item.settingName"
							:value="item.settingId"
						></el-option>
					</el-select>
				</div>
			<!-- 	<div class="selectbox">
					<span>项目状态</span>
					<el-select v-model="value" placeholder="请选择">
						<el-option
							v-for="item in companyTypeList"
							:key="item.settingId"
							:label="item.settingName"
							:value="item.settingId"
						></el-option>
					</el-select>
				</div> -->
				<el-button class="chaxun flex" @click="clickSearch">查询</el-button>
				<el-button class="chongzhi flex" @click="reSet">重置</el-button>
			</div>
		</div>
		<div class="contentbox">
			<div style="display: flex;">
				<div class="addbtn" @click="showAddProject">
					<i class="el-icon-plus"></i>
					新增
				</div>
				<div>
					<div class="addbtn" style="margin-left: 10px;">
						<el-upload
							class="upload-demo"
							ref="upload"
							:http-request=baojiaUpFileSuccess
							:action="$baseUrl + '/project/uploadProject'"
							:show-file-list="false"
							name="file"
							
							
						>
							<!-- <div slot="tip" class="el-upload__tip">上传文件类型：jpeg/jpg/png/mp4/xlsx/xls/doc/docx</div> -->
							<div class="upbox nochoosetext" style="">
								<i class="el-icon-plus"></i>
								<span>导入</span>
							</div>
						</el-upload>
						
					</div>
					<a href="http://demo.mojingkj.cn/crm/upload/y5eIBz_20221212.xlsx" target="_black" style="cursor: pointer;font-size: 12px;color: #2E77F6;padding-top: 10px;padding-left: 10px;display: block;text-decoration: none">默认模板格式下载</a>
				</div>
				
			</div>
			
			<div class="allproject">
				<div class="oneproject" v-for="item in projectLists" :key="item.projectId" @click="goDetail(item.projectId)">
					<div class="" style="display: flex;">
						<div class="bigstatus">
							<div class="statusbox">
								{{item.projectStageName || '待开始'}}
							</div>
							<div class="otherstatus">
								<div></div>
								<span>UI设计</span>
								<div></div>
								<span>前端</span>
								<div></div>
								<span>后台</span>
							</div>
						</div>
					</div>
					
					
					<div class="projectname">
						{{item.projectName}}
					</div>
					<div class="address">
						<i class="el-icon-office-building" style="color: #808080;"></i>
						<span>{{item.customerName}}</span>
					</div>
					<div class="allprogress">
						<div class="box1">
							<div class="progresstext">
								<p>{{item.completePercent || '0'}}%</p>
								<span>项目进度</span>
							</div>
							<div class="progressbox">
								<div class="" :style="{'width': item.completePercent || 0 + '%'}">
								</div>
							</div>
						</div>
						<span class="progressline"></span>
						<div class="box2">
							<p>{{item.passDays || '0'}}</p>
							<div>已进行天数</div>
						</div>
					</div>
					<div class="projectbottom">
						<div class="bottomleft">
							<div></div>
							<span v-if="item.statusChangeUser">
								{{item.statusChangeUser}}更新了项目状态 {{item.statusChangeTime}}
							</span>
						</div>
						<div class="bottomright">
							<img src="@/assets/project/image8.png" >
							<img src="@/assets/project/image9.png" >
							<span></span>
						</div>
					</div>
				</div>
				
			</div>
			<div class="pagebox pageboxdd" style="text-align: center;width: 100%;">
				<el-pagination
					background
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="projectIinfo.page"
					:page-sizes="[12]"
					:page-size="projectIinfo.pageSize"
					layout="total, sizes, prev, pager, next, jumper"
					:total="totalCount"
				></el-pagination>
			</div>
		</div>
	
		<add-project ref="addProject"></add-project>
	</div>
</template>

<script>
	import axios from 'axios'
	import { projectList,projectListTeam,uploadProject } from "@/network/api.js"
	import addProject from "./component/addProject.vue"
	import { mapState } from "vuex"
	export default {
		data() {
			return {
				value: '',
				projectLists: [],
				projectIinfo: {
					companyIndustry: '',   //所属行业
					isAsc: 'asc',
					orderByColumn: '',
					page: 1,
					pageSize: 12,
					projectName: '',  // 项目名称
					searchBody: '',
				},
				
				totalCount: 0,
				// myProjectInfo: {
				// 	companyIndustry: '',   //所属行业
				// 	isAsc: 'asc',
				// 	orderByColumn: '',
				// 	page: 1,
				// 	pageSize: 10,
				// 	projectName: '',
				// 	searchBody: '',
				// }
			}
		},
		props: {
			personteam: {
				type: Number,
			},
		},
		components: {
			addProject
		},
		computed: {
			// ...mapState(['companyTypeList'])
			...mapState('systemModule',{
				companyIndustryList: 'companyIndustryList',
			})
		},
		created() {
			this._projectList()
			// this._projectListTeam()
		},
		methods: {
			handleSizeChange(val) {
				this.projectIinfo.pageSize = val
				this._projectList()
			},
			handleCurrentChange(val) {
				this.projectIinfo.page = val
				this._projectList()
			},
			// 点击查询
			clickSearch() {
				console.log(this.personteam);
				if (this.personteam === 1) {
					this._projectList()
				} else {
					this._projectListTeam()
				}
			},
			// 点击重置
			reSet() {
				this.projectIinfo.companyIndustry = ''
				this.projectIinfo.searchBody = ''
			},
			// 跳转项目详情
			goDetail(id) {
				this.$router.push({
					path: '/projectdetail',
					query: {
						id
					}
				})
			},
			// 获取我参与的项目列表
			async _projectListTeam() {
				const res = await projectListTeam(this.projectIinfo)
				
				this.projectLists = res.data.list;
	
				
			},
			// 获取我负责的项目列表
			async _projectList() {
				const res = await projectList(this.projectIinfo)
				
				this.projectLists = res.data.list;
				this.totalCount = res.data.totalCount
				
			},
			// 点击出现添加项目弹框
			showAddProject() {
				this.$refs.addProject.drawer = true
			},
			
			// 上传报价文件成功回调 设计方案上传
			baojiaUpFileSuccess(req) {
				const config = {
					headers: {'Content-Type': 'multipart/form-data'}
				};
				const formdata = new FormData();
				formdata.append('file', req.file);
				axios.post('/project/uploadProject', formdata, config)
				.then(res => {
					if(res.data=="success"){
						  
					   this._projectList();
					   this.$myMessage('导入成功');
					}else{
						return this.$myMessage('导入失败')
					}
					console.log('13333333',res)
					//this.form.bannerUrl = res.data.data.fileUrl
				})
				.catch(err => {
					console.log('发生了错误信息',err.message)
				})
			// 	if (info.status !== 1) return this.$myMessage(info.message,"error")
			
			// 	let fileinfo = {
			// 		docName: file.name,  		//文档原始名称
			// 		docPath: info.data.fileUrl,  		//文档路径
			// 		docType: '0',  		//文档类型 0 报价方案 1 设计方案
			// 	}
			  
			
				
				//this.ruleForm.docList.push(fileinfo)
				
			},
			
		},
	}
</script>

<style scoped lang="scss">
	.bottomright {
		display: flex;
		align-items: center;
		img {
			cursor: pointer;
			width: 1.0625em;
			height: 1.0625em;
			margin: 0 0 0 1.125em;
		}
	}
	.otherstatus {
		display: flex;
		align-items: center;
		margin: 0 0 0 0.5em;
		div {
			width: 0.25em;
			height: 0.25em;
			background-color: rgba(46, 119, 246, 0.8);
			margin: 0 0.3125em 0 0;
			border-radius: 50%;
		}
		span {
			font-size: 0.8125em;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #2E77F6;
			margin: 0 0.625em 0 0;
		}
	}
	.bigstatus {
		display: flex;
		// max-width: 100%;
		// width: 17.6875em;
		padding: 0 0 0 0;
		// height: 32px;
		background: rgba(47, 121, 255, 0.05);
		border-radius: 0px 0px 0.875em 0px;
	}
	.bottomleft {
		display: flex;
		align-items: center;
		div {
			width: 0.25rem;
			height: 0.25em;
			border-radius: 50%;
			background-color: #999999;
			margin: 0 0.375em 0 0;
		}
		span {
			font-size: 0.875em;
			font-family: PingFang-SC-Regular, PingFang-SC;
			font-weight: 400;
			color: #999999;
		}
	}
	.projectbottom {
		width: calc(100% - 2.5em);
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.box2 {
		p {
			font-size: 0.9375em;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #333333;
		}
		div {
			font-size: 0.75em;
			font-family: PingFang-SC-Medium, PingFang-SC;
			font-weight: 500;
			color: #999999;
		}
	}
	.progressline {
		width: 1px;
		height: 1.9375em;
		background-color: #EEEEEE;
	}
	.box1 {
		width: 82%;
	}
	.progressbox {
		width: 100%;
		margin: 0.25em 0 0;
		height: 0.625em;
		background: #EFF3F6;
		border-radius: 0.3125em;
		div {
			height: 0.625em;
			background: linear-gradient(270deg, #74CDFF 0%, #0091FF 100%);
			border-radius: 0.3125em;
		}
	}
	.allprogress {
		width: calc(100% - 2.5em);
		margin: 7% auto;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.progresstext {
		display: flex;
		align-items: flex-end;
		
		p {
			font-size: 0.9375rem;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #333333;
			margin: 0 0.375em 0 0;
		}
		span {
			font-size: 0.75em;
			color: #999999;
		}
	}
	.address {
		display: flex;
		align-items: center;
		padding: 0 1.25em;
		i {
			margin: 0 0.625em 0 0;
		}
	}
	.projectname {
		font-size: 1.125em;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #222222;
		padding: 3.3%;
	}
	.statusbox {
		width: 7.125em;
		height: 2em;
		background-color: rgb(254,234,232);
		box-shadow: -2px 0px 4px 0px rgba(46, 119, 246, 0.2);
		border-radius: 0px 0px 0.875em 0px;
		font-size: 0.9375em;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #F22E18;
		display: flex;
		align-items: center;
		padding: 0 0 0 1.25em;
		// margin: 0 0.5em 0 0;
	}
	.allproject {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		margin: 1.25em 0 0;
	}
	.oneproject {
		cursor: pointer;
		width: calc((100% - 3.75em) / 3);
		// background-color: #000000;
		// width: 10px;
		margin: 0 1.25em 1.25em 0;
		height: 15.6vw;
		// display: flex;
		// flex-direction: column;
		min-height: 180px;
		background: #FFFFFF;
		box-shadow: 0px 0px 0.5em 0px rgba(0, 0, 0, 0.08);
		border-radius: 0.25em;
	}
	.addbtn:active {
		opacity: 0.6;
	}
	.addbtn {
		cursor: pointer;
		width: 6.75em;
		height: 2.25em;
		background: rgb(234,243,255);
		border-radius: 0.25em;
		border: 1px solid #2F79FF;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 0.875em;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #2F79FF;
		i {
			margin: 0 1.25em 0 0;
		}
	}
	.contentbox {
		width: 100%;
		margin: 0.9375em 0 0;
		padding: 1.25em 0 1.25em 1.25em;
		background-color: #ffffff;
	}
	.selectbox {
		margin: 0 0 0 2.5em;
		display: flex;
		align-items: center;
		span {
			font-size: 0.875em;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #333333;
			margin: 0 0.6875em 0 0;
		}
	}
	.inputbox {
		display: flex;
		align-items: center;
		background: #ffffff;
		border-radius: 0.25em;
		border: 1px solid #e4e4e4;
		height: 2.25em;
		width: 12.5em;
		justify-content: space-between;
		input {
			outline: none;
			border: 0;
			width: 10.25em;
			font-size: 0.875em;
			padding: 0 0.375em 0 0.875em;
		}
		div {
			width: 2.25em;
			height: 2.25em;
			background: #faf9fb;
			border-radius: 0px 0.25em 0.25em 0px;
			border: 1px solid #e4e4e4;
		}
	}
	::v-deep {
		.el-input__inner {
			color: #666666;
			width: 10.5em;
			height: 2.25em;
			line-height: 2.25em;
		}
		.el-input__icon {
			line-height: 2.25em;
		}
	}
	.tablebox {
		border-top: 1px solid #EEEEEE;
		background-color: #ffffff;
		padding: 1rem 1.25em;
		width: 100%;
		background-color: #ffffff;
	}
.addbox:active {
	opacity: 0.6;
}
.addbox {
	margin: 1.5625em 0 1.25em 0;
	cursor: pointer;
	width: 6.75em;
	height: 2.25em;
	background: linear-gradient(270deg, #2d74f4 0%, #3286ff 100%);
	border-radius: 0.25em;
	display: flex;
	align-items: center;
	justify-content: center;
	span {
		font-size: 0.875rem;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #ffffff;
		margin: 0 0 0 1.25em;
	}
}
::v-deep {
	.el-button {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
.chongzhi {
	width: 5.625em;
	height: 2.25em;
	font-size: 0.875em;
}
.chaxun {
	margin: 0 1.25em 0 2.5em;
	width: 5.625em;
	height: 2.25em;
	
	// border-radius: 0.25em;
	
	font-size: 0.875em;
	font-family: PingFang-SC-Medium, PingFang-SC;
	font-weight: 500;
	
}
.pageboxdd .btn-prev{
	margin-left: 30px !important;
}
.tabletop {
	display: flex;
	align-items: center;
}
</style>