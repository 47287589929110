<template>
	<div class="gonghai">
		<div class="tablebox">
			<div class="tabletop">
				<!-- 		<div class="inputbox">
					<input placeholder="公司名称搜索" v-model="searchInfo.customerName"/>
					<div class="flex"><i class="el-icon-search" style="color: #AAAAAA;"></i></div>
				</div> -->
				<div class="selectbox" style="margin-left: 0;">
					<span>验收状态</span>
					<el-select v-model="searchInfo.checkStatus" placeholder="请选择">
						<el-option label="未验收" value="0"></el-option>
						<el-option label="已验收" value="1"></el-option>
					</el-select>
				</div>
				<div class="selectbox">
					<span>验收项目</span>
					<el-select v-model="searchInfo.projectId" placeholder="请选择">
						<el-option
							v-for="item in projectLists"
							:key="item.projectId"
							:value="item.projectId"
							:label="item.projectName"
						></el-option>
					</el-select>
				</div>
				<el-button class="chaxun flex" @click="_projectCheckList">查询</el-button>
				<el-button class="chongzhi flex" @click="clickReset">重置</el-button>
			</div>
			<div
				class="addbox"
				style="margin-top: 1.25rem;"
				@click="showAddCheck"
				v-if="permissionList.includes('myCluesManage:add') || isAdmin"
			>
				<i class="el-icon-plus" style="color: #ffffff;"></i>
				<span>新增</span>
			</div>

			<div>
				<el-table :data="tableData" border style="width: 100%;margin-top: 1.25em;">
					<el-table-column type="index" label="序号" width="80"></el-table-column>
					<el-table-column prop="customerName" label="公司名称"></el-table-column>
					<el-table-column prop="projectName" label="项目名称"></el-table-column>
					<el-table-column prop="principalName" label="负责人"></el-table-column>
					<el-table-column prop="companyTypeName" label="状态">
						<template slot-scope="scope">
							<div class="statusbox">
								<div v-if="scope.row.checkStatus == 0">未验收</div>
								<div class="yiyanshou" v-if="scope.row.checkStatus == 1">已验收</div>
							</div>
						</template>
					</el-table-column>

					<el-table-column prop="planCheckTime" label="预计验收时间"></el-table-column>
					<el-table-column prop="realCheckTime" label="实际验收时间"></el-table-column>
					<!-- 		<el-table-column prop="address" label="位置信息">
						<template slot-scope="scope">
							<div class="textbox">
								{{scope.row.address}}
							</div>
						</template>
						
					</el-table-column> -->
					<!-- 				<el-table-column prop="customerDesc" label="公司简介">
						<template slot-scope="scope">
							<div class="textbox">
								{{scope.row.customerDesc}}
							</div>
						</template>
					</el-table-column> -->

					<el-table-column label="操作" width="250">
						<template slot-scope="scope">
							<div class="twobtn">
								<div
									class="kanbox zeng"
									@click.stop="showEdit(scope.row)"
									v-if="permissionList.includes('myCluesManage:chance') || isAdmin"
								>
									<img src="@/assets/btnicon/image3.png" />
									改
								</div>
								<div class="kanbox shan" @click="showDelete(scope.row.checkId)" v-if="isAdmin">
									<img src="@/assets/btnicon/image6.png" />
									删
								</div>
							</div>
						</template>
					</el-table-column>
				</el-table>

				<div class="pagebox">
					<el-pagination
						background
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
						:current-page="searchInfo.page"
						:page-sizes="[5, 10, 15, 20]"
						:page-size="searchInfo.pageSize"
						layout="total, sizes, prev, pager, next, jumper"
						:total="totalCount"
					></el-pagination>
				</div>
			</div>
		</div>
		<add-check ref="addCheck" @updataInfo="_projectCheckList"></add-check>
		<edit-check ref="editCheck" @updataInfo="_projectCheckList"></edit-check>
		<delete-popup
			ref="deletePopup"
			message="确认要删除验收计划吗"
			@deleteInfo="_deleteProjectCheck"
		></delete-popup>
	</div>
</template>

<script>
import { customerList, customerInfo, projectCheckList, deleteProjectCheck,projectList } from '@/network/api.js';
import { mapState } from 'vuex';

import addCheck from './component/addCheck.vue';
import editCheck from './component/editCheck.vue';
import deletePopup from '@/components/common/deletePopup.vue';

export default {
	data() {
		return {
			totalCount: 0,
			deleteId: '',
			projectLists: [],
			searchInfo: {
				checkStatus: '', // 验证状态 0 未验收 1 已验收
				isAsc: 'asc',
				orderByColumn: '',
				page: 1,
				pageSize: 10,
				principalId: '', // 验收复制人id
				searchBody: '',
				projectId: '' //项目id
			},
			tableData: []
		};
	},
	computed: {
		// ...mapState(['companyTypeList'])
		...mapState('systemModule', {
			companyTypeList: 'companyTypeList',
			cluesSourceList: 'cluesSourceList'
		}),
		permissionList() {
			return this.$store.state.permissionList;
		},
		isAdmin() {
			return this.$store.state.isadmin && localStorage.getItem('isStaff') == '0';
		}
	},
	components: {
		addCheck,
		editCheck,
		deletePopup
	},
	name: 'projectCheck',
	created() {
		this._projectCheckList();
		this._projectList()
	},
	methods: {
		// 获取我负责的项目列表
		async _projectList() {
			const res = await projectList({
				companyIndustry: '', //所属行业
				isAsc: 'asc',
				orderByColumn: '',
				page: 1,
				pageSize: 1000000,
				projectName: '',
				searchBody: ''
			});
			console.log('我负责的项目列表', res);
			this.projectLists = res.data.list;
		},
		// 删除验收计划
		async _deleteProjectCheck() {
			await deleteProjectCheck({
				checkId: this.deleteId
			});
			this.$myMessage('删除成功', 'success');
			this.$refs.deletePopup.dialogVisible = false;
			this._projectCheckList();
		},
		// 点击出现删除弹框
		showDelete(id) {
			this.deleteId = id;
			this.$refs.deletePopup.dialogVisible = true;
		},
		// 点击出现添加弹框
		showAddCheck() {
			this.$refs.addCheck.drawer = true;
		},
		// 获取验收计划列表
		async _projectCheckList() {
			const res = await projectCheckList(this.searchInfo);
			console.log('验收计划列表', res);
			this.tableData = res.data.list;
			this.totalCount = res.data.totalCount;
		},

		// 点击重置
		clickReset() {
			this.searchInfo.checkStatus = '';
			this.searchInfo.projectId = '';
			this._projectCheckList()
		},

		// 点击出现添加弹框
		showEdit(info) {
			console.log(info);
			this.$refs.editCheck.drawer = true;
			this.$refs.editCheck.ruleForm = {
				checkId: info.checkId,
				planCheckTime: info.planCheckTime, //  计划验收时间
				principalId: info.principalId, //  验收负责人id
				principalName: info.principalName, //  验收负责人姓名
				projectId: info.projectId, //  项目id
				realCheckTime: info.realCheckTime, //  实际验收时间
				checkStatus: info.checkStatus // 0 未验收 1 已验收
			};
		},

		handleSizeChange(val) {
			this.searchInfo.pageSize = val;
			this._projectCheckList();
		},
		handleCurrentChange(val) {
			this.searchInfo.page = val;
			this._projectCheckList();
		}
	}
};
</script>

<style scoped lang="scss">
.statusbox {
	display: flex;
	align-items: center;
	justify-content: center;
	div {
		width: 3.75em;
		height: 1.5em;
		background: rgba(242, 46, 24, 0.1);
		border-radius: 0.75em 0 0.75em 0.75em;
		border: 1px solid rgba(242, 46, 24, 0.6);
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 0.875em;
		font-family: PingFang-SC-Regular, PingFang-SC;
		font-weight: 400;
		color: #f22e18;
		&.yiyanshou {
			color: #3dc57c;
			border: 1px solid #3dc57c;
			background: rgba(61, 197, 124, 0.1);
		}
	}
}
.textbox {
	text-overflow: -o-ellipsis-lastline;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	line-clamp: 2;
	-webkit-box-orient: vertical;
}
.box3 {
	width: 2.5em;
	height: 0.1875em;
	background: #3286ff;
	border-radius: 0.125em;
	position: absolute;

	bottom: 0;
}
.box2 {
	display: flex;
	align-items: flex-end;
	p {
		font-size: 1rem;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #666666;
		&.activep {
			color: #000000;
			font-size: 1.125em;
		}
	}
	span {
		font-size: 0.75em;
		&.activespan {
			color: #000000;
		}
	}
}
.box1 {
	cursor: pointer;
	margin: 0 1.25em 0 0;
	height: 100%;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}
.top1 {
	border: 1px solid #eeeeee;
	width: 100%;
	height: 3.25em;
	background-color: #ffffff;
	display: flex;
	align-items: center;
	padding: 0 1.0625em;
}
::v-deep {
	.pagebox {
		width: 100%;
		display: flex;
		justify-content: flex-end;
		margin: 6.25em 0 0 1.25em;
		padding: 0 1.25em 0 0;
		.el-input__inner {
			color: #666666;
			width: 3em;
			height: 2.25em;
			line-height: 2.25em;
		}
		.el-select {
			.el-input__inner {
				color: #666666;
				width: 8em;
				height: 2.25em;
				line-height: 2.25em;
			}
		}

		.el-input__icon {
			line-height: 2.25em;
		}
	}
}
.kanbox:active {
	opacity: 0.6;
}
.kanbox {
	cursor: pointer;
	width: 3.625em;
	height: 1.625em;
	background: #0091ff;
	border-radius: 0.8125em;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 0.675em;
	font-size: 0.875em;
	font-family: PingFang-SC-Medium, PingFang-SC;
	font-weight: 500;
	color: #ffffff;
	img {
		width: 1em;
		height: 0.9375em;
	}
	&.gen {
		background-color: #fa7e33;
		margin: 0 0 0 1.25em;
		img {
			width: 0.8125em;
			height: 1.0625em;
		}
	}
	&.gai {
		background-color: #3dc57c;
		margin: 0 0 0 1.25em;
	}
	&.shan {
		background-color: #b3bcc9;
		margin: 0 0 0 1.25em;
		img {
			width: 0.9375em;
			height: 1em;
		}
	}
	&.zeng {
		background-color: #9f7eff;
		margin: 0 0 0 1.25em;
	}
	img {
		width: 0.9375em;
		height: 0.9375em;
	}
}
.twobtn {
	display: flex;
	align-items: center;
	justify-content: center;
}
::v-deep {
	.cell {
		text-align: center;
	}
	tr {
		font-size: 0.975em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 400;
		color: #868e99;
	}
	th {
		background-color: #f6f7fb !important;
		font-size: 0.975em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #5b6271;
	}
}
thead {
}
.addbox:active {
	opacity: 0.6;
}
.addbox {
	// margin: 1.5625em 0 0 0;
	cursor: pointer;
	width: 6.75em;
	height: 2.25em;
	background: linear-gradient(270deg, #2d74f4 0%, #3286ff 100%);
	border-radius: 0.25em;
	display: flex;
	align-items: center;
	justify-content: center;
	span {
		font-size: 0.875rem;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #ffffff;
		margin: 0 0 0 1.25em;
	}
}
::v-deep {
	.el-button {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
.chongzhi {
	width: 5.625em;
	height: 2.25em;
	font-size: 0.875em;
}
.chaxun {
	margin: 0 1.25em 0 2.5em;
	width: 5.625em;
	height: 2.25em;

	// border-radius: 0.25em;

	font-size: 0.875em;
	font-family: PingFang-SC-Medium, PingFang-SC;
	font-weight: 500;
}
.tabletop {
	display: flex;
	align-items: center;
}
::v-deep {
	.el-input__inner {
		color: #666666;
		width: 10.5em;
		height: 2.25em;
		line-height: 2.25em;
	}
	.el-input__icon {
		line-height: 2.25em;
	}
}
.selectbox {
	margin: 0 0 0 2.5em;
	display: flex;
	align-items: center;
	span {
		font-size: 0.875em;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		margin: 0 0.6875em 0 0;
	}
}
.tablebox {
	background-color: #ffffff;
	padding: 1rem 1.25em;
}
.inputbox {
	display: flex;
	align-items: center;
	background: #ffffff;
	border-radius: 0.25em;
	border: 1px solid #e4e4e4;
	height: 2.25em;
	width: 12.5em;
	justify-content: space-between;
	input {
		outline: none;
		border: 0;
		width: 10.25em;
		font-size: 0.875em;
		padding: 0 0.375em 0 0.875em;
	}
	div {
		width: 2.25em;
		height: 2.25em;
		background: #faf9fb;
		border-radius: 0px 0.25em 0.25em 0px;
		border: 1px solid #e4e4e4;
	}
}

.gonghai {
	padding: 0.9375em 0;
}
@media screen and (max-width: 1920px) {
	.gonghai {
		font-size: 16px;
	}
}
@media screen and (max-width: 1720px) {
	.gonghai {
		font-size: 15px;
	}
}
@media screen and (max-width: 1520px) {
	.gonghai {
		font-size: 14px;
	}
}
@media screen and (max-width: 1320px) {
	.gonghai {
		font-size: 13px;
	}
}
@media screen and (max-width: 1120px) {
	.gonghai {
		font-size: 12px;
	}
}
</style>
