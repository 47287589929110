<template>
	<div class="organization">
		<div class="topbox">
			<div class="box11">
				<div style="height: 100%;" v-for="(item, index) in toplist" :key="index">
					<div
						class="onetop nochoosetext"
						@click="chooseTop(index)"
						v-if="permissionList.includes(item.name) || isAdmin"
					>
						<span :class="{ activespan: index === topIndex }">{{ item.text }}</span>
						<div v-if="index === topIndex"></div>
					</div>
				</div>
			</div>
			<div class="box22">
				<div class="box33" @click="choosePersonOrTeam(1)">
					<span :class="{activetext: personteam == 1}">个人</span>
					<img v-if="personteam == 1" src="@/assets/project/image7.png" />
				</div>
				<div class="box33" @click="choosePersonOrTeam(2)">
					<span :class="{activetext: personteam == 2}">团队</span>
					<img v-if="personteam == 2" src="@/assets/project/image7.png" />
				</div>
			</div>
		</div>
		<project-overview v-if="topIndex == 0" ref="projectOverview"></project-overview>
		<project-list :personteam="personteam" ref="projectList" v-if="topIndex == 1"></project-list>
		<project-task v-if="topIndex == 2"></project-task>
		<project-check v-if="topIndex == 3"></project-check>
	</div>
</template>

<script>
	import projectOverview from "./projectOverview.vue"
	import projectList from "./projectList.vue"
	import projectTask from "./projectTask.vue"
	import projectCheck from "./projectCheck.vue"
export default {
	data() {
		return {
			// toplist: ['','角色管理','账号管理','权限管理'],
			personteam: 1,
			toplist: [
				{
					text: '概览',
					name: 'bumengManage'
				},
				{
					text: '项目列表',
					name: 'jueseManage'
				},
				{
					text: '任务',
					name: 'zhanghaoManage'
				},
				{
					text: '验收计划',
					name: 'bumengManage'
				}
			],
			topIndex: 0
		};
	},
	created() {
		this.setTitle()
	},
	components: {
		projectOverview,
		projectList,
		projectTask,
		projectCheck
	},
	name: 'projectManage',
	computed: {
		
		permissionList() {
			return this.$store.state.permissionList;
		},
		isAdmin() {
			return this.$store.state.isadmin && localStorage.getItem('isStaff') == '0';
		}
	},
	created() {
		this.getRoutePath();
	},
	methods: {
		setTitle() {
			document.title = this.toplist[this.topIndex].text
		},
		// 点击选择个人或团队
		choosePersonOrTeam(index) {
			this.personteam = index
			if (this.$refs.projectOverview) {
				this.$refs.projectOverview.index = index
			}
			if (this.$refs.projectList) {
				if (index == 1) {
					this.$refs.projectList._projectList()
				} else {
					this.$refs.projectList._projectListTeam()
				}
			}
			
		},
		// 获取当前路由地址
		getRoutePath() {
			let route = this.$route.path;
			// if (route == '/organization/bumengmanage') {
			// 	this.topIndex = 0
			// } else if (route == '/organization/juesemanage' || route == '/organization/addjuese') {
			// 	this.topIndex = 1
			// } else if (route == '/organization/zhanghaomanage') {
			// 	this.topIndex = 2
			// }  else if (route == '/organization/rolemanage') {
			// 	this.topIndex = 3
			// }
		},
		// 点击顶部导航
		chooseTop(index) {
			this.topIndex = index;
			this.setTitle()
			// if (index === 0) {
			// 	this.$router.push({
			// 		path: "/organization/bumengmanage"
			// 	})
			// } else if (index === 1) {
			// 	this.$router.push({
			// 		path: "/organization/juesemanage"
			// 	})
			// } else if (index === 2) {
			// 	this.$router.push({
			// 		path: "/organization/zhanghaomanage"
			// 	})
			// } else if (index === 3) {
			// 	this.$router.push({
			// 		path: "/organization/rolemanage"
			// 	})
			// }
		}
	}
};
</script>

<style scoped lang="scss">
	.activetext {
		font-size: 15px !important;
		font-family: PingFang-SC-Heavy, PingFang-SC !important;
		font-weight: 800 !important;
		color: #2E76F5 !important;
	}
.box33 {
	cursor: pointer;
	margin: 0 0 0 0.8125em;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #808080;
	img {
		margin: 0.25em 0 0;
		width: 0.625em;
		height: 0.5em;
	}
}
.box22 {
	display: flex;
	align-items: center;
}
.box11 {
	display: flex;
	align-items: center;
	height: 100%;
}
.onetop {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	margin: 0 3.75em 0 0;
	cursor: pointer;
	span {
		font-size: 1.125em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #444444;
		&.activespan {
			font-size: 1.125em;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #2d74f4;
		}
	}
	div {
		width: 1.875em;
		height: 0.1875em;
		position: absolute;
		bottom: 0;
		background-color: #3286ff;
	}
}
.topbox {
	padding: 0 1.0625em;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	// width: calc(100% - 2.5em);
	height: 3.25em;
	background-color: #ffffff;
	margin: 0.9375em auto 0;
}
.organization{
	width: calc(100% - 1.875em);
	margin: 0.9375em auto 0;
}
@media screen and (max-width: 1920px) {
	.organization {
		font-size: 16px;
	}
}
@media screen and (max-width: 1720px) {
	.organization {
		font-size: 15px;
	}
}
@media screen and (max-width: 1520px) {
	.organization {
		font-size: 14px;
	}
}
@media screen and (max-width: 1320px) {
	.organization {
		font-size: 13px;
	}
}
@media screen and (max-width: 1120px) {
	.organization {
		font-size: 12px;
	}
}
</style>
