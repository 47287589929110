import { render, staticRenderFns } from "./editCheck.vue?vue&type=template&id=3edc4408&scoped=true&"
import script from "./editCheck.vue?vue&type=script&lang=js&"
export * from "./editCheck.vue?vue&type=script&lang=js&"
import style0 from "./editCheck.vue?vue&type=style&index=0&id=3edc4408&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3edc4408",
  null
  
)

export default component.exports